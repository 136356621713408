import React, { useEffect, useState } from "react";
import Axios from "axios";
import "antd/dist/antd.min.css";
import Navbar from "../../components/NavBar/Navbar";
import { Col, Row } from "antd";

export default function Home() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/posts?_limit=10`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((actualData) => {
        setData(actualData);
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="top-app">
          <center>
            {loading && <div>Loading data please waiting...</div>}
          </center>
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          <ul>
            {data &&
              data.map(({ id, title, body }) => (
                <div>
                  <Row>
                    <Col>
                      <p>ID: {id}</p>
                      <h3>TITLE: {title}</h3>
                      <p>BODY: {body}</p>
                    </Col>
                  </Row>
                </div>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
