import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/style.scss";
import Home from "./module/page/Home";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content">
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
